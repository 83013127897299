/* You can add global styles to this file, and also import other style files */
@import "styles/_variables.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~firebaseui/dist/firebaseui.css";
@import "@angular/material/theming";

@include mat-core();

$primary: $theme-primary-palette;
$accent: $theme-accent-palette;
$warn: $theme-warn-palette;

$theme: mat-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
    ));
@include angular-material-theme($theme);

.table-info {
  background-color: mat-color($accent, 100);
}

.mat-drawer {
  background-color: mat-color($accent, 100);
}

.primary-text {
  color: mat-color($primary) !important;
}

.primary-bgcolor {
  background-color: mat-color($primary) !important;
  color: white !important;
}

.bg-primary {
  background-color: mat-color($primary) !important;
}

.bg-accent {
  background-color: mat-color($accent) !important;
}

.bg-warn {
  background-color: mat-color($warn) !important;
}

.bg-primary-light {
  background-color: mat-color($primary, 200) !important;
}

.bg-accent-light {
  background-color: mat-color($accent, 200) !important;
}

.bg-warn-light {
  background-color: mat-color($warn, 200) !important;
}

.mheight-150 {
  min-height: 150px !important;
  max-height: 150px !important;
}

.mheight-200 {
  min-height: 200px !important;
  max-height: 200px !important;
}

.mheight-300 {
  min-height: 300px !important;
  max-height: 300px !important;
}

.mat-list-single-selected-option {
  background-color: mat-color($warn, A400) !important;
}

.mat-expansion-panel-body {
  background-color: mat-color($accent, A400);
}

.mat-expansion-panel-header {
  background-color: mat-color($primary) !important;
}

.mat-card {
  background-color: #eff5fb;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.table {
  background-color: mat-color($accent, A400);
}

.mat-sidenav {
  background-color: mat-color($accent, A400);
}

.mat-icon-text {
  display: flex;
  align-items: center;
}

@mixin primary-bgcolor($theme) {
  $config: mat-get-color-config($theme);
  $primary: map-get($config, primary);

  .primary-bgcolor {
    background-color: mat-color($primary) !important;
  }
}

@mixin data-table-color($theme) {
  $config: mat-get-color-config($theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .data-table {
    background-color: mat-color($primary);
    border-color: mat-color($accent, A400);
  }
}

@mixin data-table-typography($theme) {
  $config: mat-get-typography-config($theme);

  .data-table {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}

@mixin data-table-theme($theme) {
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  @if $color  !=null {
    @include data-table-color($color);
  }

  @if $typography  !=null {
    @include data-table-typography($typography);
  }
}

@include data-table-theme($theme);

html,
body {
  height: 100%;
}

body {
  background-color: white;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-toolbar {
  height: 60px !important;
  color: white !important;
}

.spacer {
  flex: 1 1 auto;
}

.mat-toolbar.mat-primary {
  color: white !important;
}

.books-virtual-scroll-viewport {
  width: 100%;
  height: calc(100vh - 360px);
}

.main-scrollviewport {
  width: 100%;
  height: calc(100vh - 120px);
}

.title-center {
  margin: 0 auto;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}